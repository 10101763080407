export default function ({ $axios, app, $recaptcha, store, $config }) {
  $axios.defaults.validateStatus = (status) => {
    return (status >= 200 && status < 300) || [418].includes(status)
  }

  $axios.onRequest(async (config) => {
    config.data = config.data || {}
    if ($config.TEST_ORDER) {
      config.data.test = 1
    }

    config.data.frontend_id = $config.FRONTEND_ID

    if (process.client) {
      if (['PUT', 'POST', 'DELETE'].includes(config.method.toUpperCase())) {
        try {
          config.data.recaptcha = await $recaptcha.execute('submit')
        } catch (e) {}
      }
    }

    config.headers.frontendid = $config.FRONTEND_ID
    config.headers.language = app.i18n.locale

    const session =
      app.$cookiz.get('ts__session') || store.getters['cookies/session']

    if (session) {
      config.headers.fuelfid = session
    }

    return config
  })

  $axios.onResponse(async (response) => {
    if (response.status === 418) {
      response.data.waiting = true
    }

    try {
      const session =
        app.$cookiz.get('ts__session') || store.getters['cookies/session']
      const { fuelfid } = response.headers
      if (fuelfid && session && session !== fuelfid && process.client) {
        await app.$cookiz.set('ts__session', fuelfid, {
          sameSite: 'none',
          secure: true,
          path: '/'
        })
        await store.dispatch('cookies/setSession', fuelfid)
      }
    } catch (e) {
      console.log(e)
    }

    return response
  })

  // $axios.onError((err) => {})

  // $axios.onRequestError((err) => {})

  $axios.onResponseError((err) => {
    let message = err?.message
    const response = err?.response

    if (response) {
      const { status, data } = err.response
      const { errors } = data
      if (!!data && data?.message) {
        message = data.message
      }

      if (process.client && status !== 425 && message) {
        const { i18n } = data
        app.$notify({
          type: 'error',
          group: 'ts',
          text: i18n && app.i18n.te(i18n) ? app.i18n.t(i18n) : message
        })
      }

      if (status === 400 && errors && Array.isArray(errors)) {
        const fields = {}
        errors.map((error) => {
          fields[error.field] = [
            app.i18n.te(error.i18n) ? app.i18n.t(error.i18n) : error.message
          ]

          return error
        })

        store.dispatch('errors/setFields', fields)
      }
    }

    return Promise.reject(err)
  })
}
