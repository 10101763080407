import { gtmDataLayer } from '~/utils/ga'
import { gtmInjectBodyScript } from '~/utils/pixels/gtm'

export default async function ({ $cookiz, $config }) {
  const cookies = await $cookiz.get('ts__cookie__consent')
  if (cookies) {
    const analytics_storage = cookies.includes('statistics')
      ? 'granted'
      : 'denied'
    const ad = cookies.includes('marketing') ? 'granted' : 'denied'

    gtmDataLayer('consent', 'update', {
      ad_user_data: ad,
      ad_personalization: ad,
      ad_storage: ad,
      analytics_storage
    })

    gtmInjectBodyScript($config.gtm.id)
  }
}
