import Vue from 'vue'
import Rollbar from 'rollbar'

const createRollbarInstance = function (accessToken) {
  const config = {"captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"production","clients":{"javascript":{"source_map_enabled":true,"code_version":"1.4.50","guess_uncaught_frames":true}}}}
  config.accessToken = accessToken
  return new Rollbar(config)
}

const serverRollbar = createRollbarInstance('fb4af40362f245509dca3dc0ee31ada7')

const clientRollbar = createRollbarInstance('2708df7cceef4b20b9427d44d092f39f')

const noop = function () {}
const stubRollbar = Object.create(null)
Object.defineProperties(
  stubRollbar,
  Object.getOwnPropertyNames(Rollbar.prototype).reduce(function (acc, key) {
    acc[key] = { value: noop }
    return acc
  }, {}))

const RollbarAdapter = Object.create(null)
Object.defineProperty(RollbarAdapter, 'instance', {
  get() {
    if (process.server) {
      return serverRollbar
    }

    if (process.client) {
      return clientRollbar
    }

    return stubRollbar
  },
})

export default function (ctx, inject) {
  // Inject Rollbar to the context as $rollbar
  ctx.$rollbar = RollbarAdapter.instance
  inject('rollbar', RollbarAdapter.instance)
  Vue.rollbar = RollbarAdapter.instance
}
